// Typography variables

// Heading sizes - mobile
// h4-h6 remain the same size on both mobile & desktop
$h00-size-mobile: 40px !default;
$h0-size-mobile: 32px !default;
$h1-size-mobile: 26px !default;
$h2-size-mobile: 22px !default;
$h3-size-mobile: 18px !default;

// Heading sizes - desktop
$h00-size: 48px !default;
$h0-size: 40px !default;
$h1-size: 32px !default;
$h2-size: 24px !default;
$h3-size: 20px !default;
$h4-size: 16px !default;
$h5-size: 14px !default;
$h6-size: 12px !default;

$font-size-small: 12px !default;

// Font weights
$font-weight-bold: var(--base-text-weight-semibold, 600) !default;
$font-weight-semibold: var(--base-text-weight-medium, 500) !default;
$font-weight-normal: var(--base-text-weight-normal, 400) !default;
$font-weight-light: var(--base-text-weight-light, 300) !default;

// Line heights
$lh-condensed-ultra: 1 !default;
$lh-condensed: 1.25 !default;
$lh-default: 1.5 !default;

// Font stacks
$body-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Noto Sans', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji' !default;

// Monospace font stack
// Note: SFMono-Regular needs to come before SF Mono to fix an older version of the font in Chrome
$mono-font: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace !default;

// The base body size
$body-font-size: 14px !default;
$body-line-height: $lh-default !default;
