// stylelint-disable comment-empty-line-before, primer/typography

// Type scale variables found in ../support/lib/variables.scss
// $h00-size-mobile: 40px;
// $h0-size-mobile: 32px;
// $h1-size-mobile: 26px;
// $h2-size-mobile: 22px;
// $h3-size-mobile: 18px;
// $h00-size: 48px;
// $h0-size: 40px;
// $h1-size: 32px;
// $h2-size: 24px;
// $h3-size: 20px;
// $h4-size: 16px;
// $h5-size: 14px;
// $h6-size: 12px;

/* Set the font size to 26px */
.h1 {
  font-size: var(--h1-size-mobile, $h1-size-mobile) !important;

  @include breakpoint(md) {
    font-size: var(--h1-size, $h1-size) !important;
  }
}

/* Set the font size to 22px */
.h2 {
  font-size: var(--h2-size-mobile, $h2-size-mobile) !important;

  @include breakpoint(md) {
    font-size: var(--h2-size, $h2-size) !important;
  }
}

/* Set the font size to 18px */
.h3 {
  font-size: var(--h3-size-mobile, $h3-size-mobile) !important;

  @include breakpoint(md) {
    font-size: var(--h3-size, $h3-size) !important;
  }
}

/* Set the font size to #{$h4-size} */
.h4 {
  font-size: var(--h4-size, $h4-size) !important;
}

/* Set the font size to #{$h5-size} */
.h5 {
  font-size: var(--h5-size, $h5-size) !important;
}

// Does not include color property like typography base
// styles, color should be applied with color utilities.
/* Set the font size to #{$h6-size} */
.h6 {
  font-size: var(--h6-size, $h6-size) !important;
}

// Heading utilities
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: var(--base-text-weight-semibold, $font-weight-bold) !important;
}

// Type utilities that match type sale
/* Set the font size to 26px */
.f1 {
  font-size: var(--h1-size-mobile, $h1-size-mobile) !important;

  @include breakpoint(md) {
    font-size: var(--h1-size, $h1-size) !important;
  }
}

/* Set the font size to 22px */
.f2 {
  font-size: var(--h2-size-mobile, $h2-size-mobile) !important;

  @include breakpoint(md) {
    font-size: var(--h2-size, $h2-size) !important;
  }
}

/* Set the font size to 18px */
.f3 {
  font-size: var(--h3-size-mobile, $h3-size-mobile) !important;

  @include breakpoint(md) {
    font-size: var(--h3-size, $h3-size) !important;
  }
}

/* Set the font size to #{$h4-size} */
.f4 {
  font-size: var(--h4-size, $h4-size) !important;

  @include breakpoint(md) {
    font-size: var(--h4-size, $h4-size) !important;
  }
}

/* Set the font size to #{$h5-size} */
.f5 {
  font-size: var(--h5-size, $h5-size) !important;
}

/* Set the font size to #{$h6-size} */
.f6 {
  font-size: var(--h6-size, $h6-size) !important;
}

// Type utils with light weight that match type scale
/* Set the font size to 40px and weight to light */
.f00-light {
  font-size: var(--h00-size-mobile, $h00-size-mobile) !important;
  font-weight: var(--base-text-weight-light, $font-weight-light) !important;

  @include breakpoint(md) {
    font-size: var(--h00-size, $h00-size) !important;
  }
}

/* Set the font size to 32px and weight to light */
.f0-light {
  font-size: var(--h0-size-mobile, $h0-size-mobile) !important;
  font-weight: var(--base-text-weight-light, $font-weight-light) !important;

  @include breakpoint(md) {
    font-size: var(--h0-size, $h0-size) !important;
  }
}

/* Set the font size to 26px and weight to light */
.f1-light {
  font-size: var(--h1-size-mobile, $h1-size-mobile) !important;
  font-weight: var(--base-text-weight-light, $font-weight-light) !important;

  @include breakpoint(md) {
    font-size: var(--h1-size, $h1-size) !important;
  }
}

/* Set the font size to 22px and weight to light */
.f2-light {
  font-size: var(--h2-size-mobile, $h2-size-mobile) !important;
  font-weight: var(--base-text-weight-light, $font-weight-light) !important;

  @include breakpoint(md) {
    font-size: var(--h2-size, $h2-size) !important;
  }
}

// Same size and weight as .lead but without color property
/* Set the font size to 18px and weight to light */
.f3-light {
  font-size: var(--h3-size-mobile, $h3-size-mobile) !important;
  font-weight: var(--base-text-weight-light, $font-weight-light) !important;

  @include breakpoint(md) {
    font-size: var(--h3-size, $h3-size) !important;
  }
}

// Smallest text size
/* Set the font size to ${#h6-size} */
.text-small {
  font-size: var(--h6-size, $h6-size) !important;
}

// 12px

/* Large leading paragraphs */
.lead {
  // stylelint-disable-next-line primer/spacing
  margin-bottom: 30px;
  font-size: var(--h3-size, $h3-size);
  font-weight: var(--base-text-weight-light, $font-weight-light);
}

// Line-height variations
// Close to commonly used line-heights. Most line-heights
// combined with type size equate to whole pixels.
// Will be improved with future typography scale updates.
// Responsive line-height
@each $breakpoint, $variant in $responsive-variants {
  @include breakpoint($breakpoint) {

    /* Set the line height to ultra condensed */
    .lh#{$variant}-condensed-ultra {
      line-height: $lh-condensed-ultra !important;
    }

    /* Set the line height to condensed */
    .lh#{$variant}-condensed {
      line-height: $lh-condensed !important;
    }

    /* Set the line height to default */
    .lh#{$variant}-default {
      line-height: $lh-default !important;
    }

    /* Set the line height to zero */
    .lh#{$variant}-0 {
      line-height: 0 !important;
    }
  }
}

// Text alignments
// Responsive text alignment
@each $breakpoint, $variant in $responsive-variants {
  @include breakpoint($breakpoint) {

    /* Text align to the right */
    .text#{$variant}-right {
      text-align: right !important;
    }

    /* Text align to the left */
    .text#{$variant}-left {
      text-align: left !important;
    }

    /* Text align to the center */
    .text#{$variant}-center {
      text-align: center !important;
    }
  }
}

// Text styles
/* Set the font weight to normal */
.text-normal {
  font-weight: $font-weight-normal !important;
}

/* Set the font weight to bold */
.text-bold {
  font-weight: $font-weight-bold !important;
}

.text-semibold {
  font-weight: $font-weight-semibold !important;
}

.text-light {
  font-weight: $font-weight-light !important;
}

/* Set the font to italic */
.text-italic {
  font-style: italic !important;
}

/* Make text uppercase */
.text-uppercase {
  text-transform: uppercase !important;
}

/* Underline text */
.text-underline {
  text-decoration: underline !important;
}

/* Don't underline text */
.no-underline {
  text-decoration: none !important;
}

/* Don't wrap white space */
.no-wrap {
  white-space: nowrap !important;
}

/* Normal white space */
.ws-normal {
  white-space: normal !important;
}

/* Force long "words" to wrap if they exceed the width of the container */
.wb-break-word {
  word-break: break-word !important;
  // this is for backwards compatibility with browsers that don't respect overflow-wrap
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}

/*
 * Specifically apply word-break: break-all; per MDN:
 *
 * > Note: In contrast to `word-break: break-word` and `overflow-wrap: break-word`,
 * > `word-break: break-all` will create a break at the exact place where text would
 * > otherwise overflow its container (even if putting an entire word on its own line
 * > would negate the need for a break).
 *
 * see: https://developer.mozilla.org/en-US/docs/Web/CSS/word-break#Values
 */
.wb-break-all {
  word-break: break-all !important;
}

.text-emphasized {
  font-weight: var(--base-text-weight-semibold, $font-weight-bold);
}

// List styles
.list-style-none {
  list-style: none !important;
}

/* Set to monospace font */
.text-mono {
  font-family: $mono-font !important;
}

/* Disallow user from selecting text */
.user-select-none {
  user-select: none !important;
}

/* Make text capitalized (transforms first character to uppercase) */
.text-capitalize {
  text-transform: capitalize !important;
}
