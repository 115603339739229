@import '@primer/css/index.scss';

$footer-height: 140px;

// $header-color: #fafbfc;
$header-color: #fff;

@mixin navbar-boxshadow {
  -webkit-box-shadow: rgba(0, 0, 0, 0.08) 0 0 5px 1px;
  -moz-box-shadow: rgba(0, 0, 0, 0.08) 0 0 5px 1px;
  box-shadow: rgba(0, 0, 0, 0.08) 0 0 5px 1px;
  border-bottom: 1px solid #e2e2e2;
}

html,
body {
  height: 100vh;
  margin: 0;
}

header {
  min-height: 50px;
}

footer {
  min-height: 50px;
}

body {
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
}

.layout {
  &.homepage {
    .primary-logo {
      margin-right: 5px;
      top: 9px;
    }
  }
}

h1.h3 {
  margin-top: -9px;
}

.form-group textarea.form-control {
  height: unset;
  min-height: unset;
}

.SignUpPage {
  h1 {
    font-size: 45px;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 1.1;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.GithubLogin {
  >div {
    margin: 0 !important;
  }
}

.SignUp {
  border-color: #4043467d !important;

  &:hover {
    border-color: #404346 !important;
    color: #000;
    background: #80808014;
    transition-duration: 150ms;
  }
}

select {
  -moz-appearance: window;
  -webkit-appearance: none;
}

.about {
  max-width: 630px;

  p {
    font-size: 16px;
    margin-top: 16px;
  }
}

.link-url {
  &.css-truncate.css-truncate-target,
  &.css-truncate .css-truncate-target {
    max-width: 100%;
  }
}

.Item-row {
  border-left-width: 5px;
  border-left-style: solid;
  border-left-color: transparent;

  &.Box-row--hover-gray:first-of-type {
    border-top-color: #e1e4e8 !important;
  }
}

.Label {
  margin-right: 0.5rem;
  white-space: normal;
}

.loader {
  margin: auto;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #a0a0a0;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.activity-line-item {
  @extend .f4;

  a,
  a:hover {
    @extend .Link--primary;
    @extend .text-underline;
  }
}

input[type=text].form-control {
  width: 100%;
}

.Review {
  .form-checkbox {
    input {
      margin: 2px 0 0 -20px;
    }
  }

  &--reviewed {
    a {
      color: #959da5 !important;
    }
  }
}

.synonym-drag-handle {
  margin-right: 1em;
}

.mobile-menu {
  @include navbar-boxshadow;
  background: $header-color;

  .mobile-menu-header {
    width: 100%;
    display: flex;

    .menu-logo {
      display: flex !important;
      align-items: center;
      flex: 1;

      svg {
        display: inline-block;
        margin-right: 7px;
      }
    }

    .menu-three-bars {
      display: inline-block;
      color: #444d56;
    }

    .menu-btn {
      background-color: inherit;
      border: 1px solid rgba(27, 31, 35, 0.2);
      color: #444d56;
    }
  }

  .menu {
    margin-bottom: 0;
  }
}

.review-checkbox {
  min-width: 87px;
  display: inline-block;
}

.gray-link {
  color: inherit;
  text-decoration: underline;
}

.innerHeader {
  flex: 1;
  flex-wrap: wrap;
  /*height: 50px;*/
  display: flex;
  align-items: center;
  padding-top: 9px;
  padding-bottom: 9px;
}

.primaryLogo {
  display: inline-block;
  margin-top: 8px;
  font-family: Avenir Next, Verdana, Helvetica;
  letter-spacing: 1px;
  font-weight: 500;
  color: #24292e !important;
}

.navLeft {
  flex: 1;
  order: 1;
  display: flex;
  justify-content: flex-start;
}

.searchBox {
  flex: 3;
  order: 2;
  justify-content: center;
  max-width: 982px;
}

.userNav {
  flex: 1;
  display: flex;
  order: 3;
  justify-content: flex-end;
  align-items: center;
}

.outerHeader {
  -webkit-box-shadow: rgba(0, 0, 0, 0.08) 0 0 5px 1px;
  -moz-box-shadow: rgba(0, 0, 0, 0.08) 0 0 5px 1px;
  box-shadow: rgba(0, 0, 0, 0.08) 0 0 5px 1px;
  border-bottom: 1px solid #e2e2e2;
  width: 100%;
}

.container {
  width: 100%;
}

.weirdDiv {
  width: '100%';
}

.dropdown {
  margin-left: 0.5em;
  display: inline-block;
}

.dropdown summary {
  background-image: unset;
  background-color: unset;
  padding: 6px 6px;
  border: none;
}

.summary {
  display: flex;
  align-items: center;
}

.laytoutComponent {
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 140px;
}

.synonyms {
  color: #444d56;
  margin-top: 3px;
}

.synonym:after {
  content: "・";
  margin: 0 3px;
}

.synonym:last-child:after {
  content: '';
  margin: 0 3px;
}

.lineItemDescription {
  display: inline;
}

.lineItemDescription p {
  display: inline;
}

.lineItemDescription p a {
  color: inherit;
  text-decoration: underline;
}

.lineItemDescription p a:hover {
  color: #0366d6;
}

.lineItemTimeAgo {
  margin-left: 1em;
  display: inline;
  font-size: smaller;
  color: #6a737d;
}

.homepageContainer {
  max-width: 720px;
  margin: auto;
}

.homepageContainer>p {
  margin-bottom: 3em;
}

.homepageList {
  margin-bottom: 3em;
}

.homepageSearch {
  margin-top: 3em !important;
}

.homepageHero {
  font-family: Avenir Next, Verdana, Helvetica;
  font-weight: 600;
  position: relative;
  width: 100%;
  margin: auto auto 2em auto;
  padding: 3em;
  overflow: hidden;
  color: white;
}

.homepageBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("hero.jpg");
  background-size: cover;
  background-position: left 0 bottom 400px;
  opacity: 0.85;
  filter: blur(4.5px);
  z-index: -1;
  transform: scale(1.03);
}

.homepageAppName {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 6px;
  margin-bottom: 9px;
  color: white;
}

.homepageSubtitle {
  font-size: 20px;
  padding-bottom: 10px;
}

.homepageDescription {
  font-size: 16px;
  margin: auto auto 2em auto;
  text-align: center;
  max-width: 732px;
}

.homepageContent {
  max-width: 804px;
  text-align: center;
  margin: auto;
  margin-top: 16px;
}

@media (max-width: 1280px) {
  .homepageContent {
    width: 70%;
  }
}

@media (max-width: 1012px) {
  .homepageContent {
    width: 95%;
  }

  .homepageBackground {
    background-position: left 0 bottom 0;
  }
}

a.homepageLogo {
  text-decoration: none;
}

.hompageLogo,
.hompageLogo:hover {
  text-decoration: none !important;
  color: inherit;
  filter: blur(0);
}

.searchBoxInput {
  font-size: 24px !important;
  background-color: white !important;
  border: 3px solid #1d68da17 !important;
}

.searchBoxInnerSearchBox {
  width: 100%;
  margin: 0;
  display: grid !important;
  align-items: stretch;
  grid-template-columns: 5fr auto;
  grid-template-rows: 100%;
}

.searchBoxInnerButton {
  display: block;
  height: 100%;
}

.searchBoxInnerButtonContainer {
  display: grid !important;
}

.public-DraftEditorPlaceholder-inner {
  position: absolute;
  color: #aaaaaa;
}

.textInputTextInput {
  width: 100%;
  padding: 6px;
  z-index: 1;
}

.Subhead {
  line-height: 1.3;
  display: block;
}

.Subhead-heading {
  font-size: 22px !important;
  font-weight: 400;
}

.topicTimeRangeFormStartsAt {
  border: 1px solid #d1d5da;
  padding: 4px;
  padding-left: 7px;
  border-radius: 3px;
}

.topicTimeRangeFormFormElements {
  display: flex;
}

.topicTopicRow .Box-row-link {
  font-weight: 600;
}

.SelectedRepo-banner {
  width: 100%;
  padding: 5px;
  text-align: center;
}

.RepoOwnership {
  width: 60px;
  height: 3px;
}

.logo-container {
  display: flex;
  align-items: center;
}
