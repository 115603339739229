/* stylelint-disable primer/no-deprecated-colors */
.btn-mktg {
  position: relative;
  z-index: 1;
  display: inline-block;
  // stylelint-disable-next-line primer/spacing
  padding: 0.9rem 1.5rem 1.1rem;
  // stylelint-disable-next-line primer/typography
  font-size: 1rem;
  font-weight: $font-weight-bold;
  line-height: 1;
  color: var(--color-canvas-default);
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  background:
    linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%),
    var(--color-mktg-btn-bg) !important;
  border: 0;
  // stylelint-disable-next-line primer/borders
  border-radius: 0.375rem;
  transition: box-shadow 0.2s, outline 0.2s ease;
  appearance: none !important;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: '';
    // stylelint-disable-next-line primer/colors
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%) !important;
    border-radius: inherit;
    opacity: 0;
    opacity: 0;
    transition: opacity 0.2s;
    background-blend-mode: normal;
  }

  &:hover {
    text-decoration: none;
    box-shadow: var(--color-mktg-btn-shadow-hover) !important;
  }

  &:hover,
  &:focus,
  &:focus-visible,
  &.focus {
    &::before {
      opacity: 1;
    }
  }

  // fallback :focus state
  &:focus {
    @include focusOutline(2px, var(--color-accent-fg));

    // remove fallback :focus if :focus-visible is supported
    &:not(:focus-visible) {
      outline: solid 1px transparent;
      box-shadow: none;
    }
  }

  // default focus state
  &:focus-visible {
    @include focusOutline(2px, var(--color-accent-fg));
  }

  &:active {
    &::before {
      opacity: 0.5 !important;
    }
  }

  &.disabled,
  &[disabled] {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }
}

.btn-muted-mktg {
  color: var(--color-fg-default) !important;
  background: none !important;
  box-shadow: var(--color-mktg-btn-shadow-outline);

  &::before {
    display: none;
  }

  &:hover {
    box-shadow: var(--color-mktg-btn-shadow-hover-muted) !important;
  }

  &:active {
    // stylelint-disable-next-line primer/box-shadow
    box-shadow: var(--color-fg-default) 0 0 0 3px inset !important;
  }

  &:disabled {
    // stylelint-disable-next-line primer/box-shadow
    box-shadow: var(--color-fg-subtle) 0 0 0 1px inset !important;
  }
}

.btn-subtle-mktg {
  color: var(--color-fg-default) !important;
  background: none !important;
  box-shadow: none !important;

  &::before {
    background: none !important;
  }

  &:hover {
    box-shadow: var(--color-mktg-btn-shadow-hover-muted) !important;
  }
}

.btn-signup-mktg {
  // stylelint-disable-next-line primer/colors
  color: #fff;
  // stylelint-disable-next-line primer/colors
  background: linear-gradient(180deg, rgba(52, 183, 89, 0.15) 0%, rgba(46, 164, 79, 0) 100%), rgb(46, 164, 79) !important;

  &::before {
    // stylelint-disable-next-line primer/colors
    background: linear-gradient(180deg, rgba(52, 183, 89, 0.15) 0%, rgba(46, 164, 79, 0) 100%) !important;
  }

  // fallback :focus state
  &:focus {
    @include focusOutline(2px, var(--color-accent-fg));

    // remove fallback :focus if :focus-visible is supported
    &:not(:focus-visible) {
      outline: solid 1px transparent;
      box-shadow: none;
    }
  }

  // default focus state
  &:focus-visible {
    @include focusOutline(2px, var(--color-accent-fg));
  }
}

// Size modifiers

.btn-small-mktg {
  // stylelint-disable-next-line primer/spacing
  padding: 0.625rem 1rem 0.8125rem;
}

.btn-large-mktg {
  // stylelint-disable-next-line primer/spacing
  padding: 16px 30px 20px !important;
  // stylelint-disable-next-line primer/typography
  font-size: 1.25rem;
}
