// Toast

.Toast {
  display: flex;
  margin: $spacer-2;
  color: var(--color-fg-default);
  background-color: var(--color-canvas-default);
  border-radius: $border-radius;
  box-shadow: inset 0 0 0 1px var(--color-border-default), var(--color-shadow-large);

  @include breakpoint(sm) {
    width: max-content;
    max-width: 450px;
    margin: $spacer-3;
  }
}

.Toast-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $spacer-3 * 3;
  flex-shrink: 0;
  color: var(--color-fg-on-emphasis);
  background-color: var(--color-accent-emphasis);
  border: $border-width $border-style transparent;
  border-right: 0;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.Toast-content {
  padding: $spacer-3;
}

.Toast-dismissButton {
  max-height: 54px; // keeps button aligned to the top
  padding: $spacer-3;
  color: inherit;
  background-color: transparent;
  border: 0;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }
}

// Modifier

.Toast--loading {
  color: var(--color-fg-default);
  box-shadow: inset 0 0 0 1px var(--color-border-default), var(--color-shadow-large);

  .Toast-icon {
    background-color: var(--color-neutral-emphasis);
  }
}

.Toast--error {
  color: var(--color-fg-default);
  box-shadow: inset 0 0 0 1px var(--color-border-default), var(--color-shadow-large);

  .Toast-icon {
    background-color: var(--color-danger-emphasis);
  }
}

.Toast--warning {
  color: var(--color-fg-default);
  box-shadow: inset 0 0 0 1px var(--color-border-default), var(--color-shadow-large);

  .Toast-icon {
    background-color: var(--color-attention-emphasis);
  }
}

.Toast--success {
  color: var(--color-fg-default);
  box-shadow: inset 0 0 0 1px var(--color-border-default), var(--color-shadow-large);

  .Toast-icon {
    background-color: var(--color-success-emphasis);
  }
}

// Animations

.Toast--animateIn {
  animation: Toast--animateIn 0.18s cubic-bezier(0.22, 0.61, 0.36, 1) backwards;
}

@keyframes Toast--animateIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
}

.Toast--animateOut {
  animation: Toast--animateOut 0.18s cubic-bezier(0.55, 0.06, 0.68, 0.19) forwards;
}

@keyframes Toast--animateOut {
  100% {
    pointer-events: none;
    opacity: 0;
    transform: translateY(100%);
  }
}

.Toast--spinner {
  animation: Toast--spinner 1000ms linear infinite;
}

@keyframes Toast--spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
