// stylelint-disable selector-max-type, selector-max-specificity, max-nesting-depth, selector-no-qualifying-type

.details-overlay[open] > summary::before {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 80;
  display: block;
  cursor: default;
  content: ' ';
  background: transparent;
}

.details-overlay-dark[open] > summary::before {
  z-index: 111;
  background: var(--color-primer-canvas-backdrop);
}

.details-reset {
  // Remove marker added by the display: list-item browser default
  > summary {
    list-style: none;
    transition: 80ms cubic-bezier(0.33, 1, 0.68, 1);
    transition-property: color, background-color, box-shadow, border-color;
    // fallback :focus state
    &:focus {
      @include focusOutline;

      // remove fallback :focus if :focus-visible is supported
      &:not(:focus-visible) {
        outline: solid 1px transparent;
      }
    }

    // default focus state
    &:focus-visible {
      @include focusOutline;
    }

    &.btn-primary {
      // fallback :focus state
      &:focus {
        @include focusOutlineOnEmphasis;

        // remove fallback :focus if :focus-visible is supported
        &:not(:focus-visible) {
          outline: solid 1px transparent;
          box-shadow: none;
        }
      }

      // default focus state
      &:focus-visible {
        @include focusOutlineOnEmphasis;
      }
    }
  }
  // Remove marker added by details polyfill
  > summary::before {
    display: none;
  }
  // Remove marker added by Chrome
  > summary::-webkit-details-marker {
    display: none;
  }
}

.details-overlay > summary {
  transition: 80ms cubic-bezier(0.33, 1, 0.68, 1);
  transition-property: color, background-color, box-shadow, border-color;
  // fallback :focus state
  &:focus {
    @include focusOutline;

    // remove fallback :focus if :focus-visible is supported
    &:not(:focus-visible) {
      outline: solid 1px transparent;
    }
  }

  // default focus state
  &:focus-visible {
    @include focusOutline;
  }

  &.btn-primary {
    // fallback :focus state
    &:focus {
      @include focusOutlineOnEmphasis;

      // remove fallback :focus if :focus-visible is supported
      &:not(:focus-visible) {
        outline: solid 1px transparent;
        box-shadow: none;
      }
    }

    // default focus state
    &:focus-visible {
      @include focusOutlineOnEmphasis;
    }
  }
}
